.replayer-wrapper {
  position: relative;
}
.replayer-mouse {
  position: absolute;
  width: 20px;
  height: 20px;
  transition: 0.05s linear;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMwMCIgd2lkdGg9IjMwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBkYXRhLW5hbWU9IkxheWVyIDEiIHZpZXdCb3g9IjAgMCA1MCA1MCI+PHBhdGggZD0iTTQ4LjcxIDQyLjkxTDM0LjA4IDI4LjI5IDQ0LjMzIDE4YTEgMSAwIDAwLS4zMy0xLjYxTDIuMzUgMS4wNmExIDEgMCAwMC0xLjI5IDEuMjlMMTYuMzkgNDRhMSAxIDAgMDAxLjY1LjM2bDEwLjI1LTEwLjI4IDE0LjYyIDE0LjYzYTEgMSAwIDAwMS40MSAwbDQuMzgtNC4zOGExIDEgMCAwMC4wMS0xLjQyem0tNS4wOSAzLjY3TDI5IDMyYTEgMSAwIDAwLTEuNDEgMGwtOS44NSA5Ljg1TDMuNjkgMy42OWwzOC4xMiAxNEwzMiAyNy41OEExIDEgMCAwMDMyIDI5bDE0LjU5IDE0LjYyeiIvPjwvc3ZnPg==");
}
.replayer-mouse:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #4950f6;
  transform: translate(-10px, -10px);
  opacity: 0.3;
}
.replayer-mouse.active:after {
  animation: click 0.2s ease-in-out 1;
}
.replayer-mouse-tail {
  position: absolute;
  pointer-events: none;
}
@keyframes click {
  0% {
    opacity: 0.3;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    transform: translate(-10px, -10px);
  }
  50% {
    opacity: 0.5;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    transform: translate(-5px, -5px);
  }
}